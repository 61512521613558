import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import TheaterVideo from "../components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ReviewRow from "../components/ReviewRow"
import CTA from "../components/CTA/CTA"
import GetStarted from "../components/CTA/GetStarted"
import HeadingParagraphButton from "../components/CTA/HeadingParagraphButton"

import { Dialog } from "@blueprintjs/core"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { instructions } from "../cms/collections/instructions"
import stars from "../images/stars.svg"
import VideoButton from "../components/Theater/VideoButton"

var classNames = require("classnames")

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

class WhyChoosePage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    return (
      <SharedStateProvider>
        <Layout pageTitle="why-choose-page">
          <SEO title={post.metaTitle} description={post.metaDescription} />

          <div>
            <Button buttonText="BACK" goBack />
            <section
              style={{ paddingBottom: post.imageId ? "80px" : "30px" }}
              className="procedure top-section section white-back"
            >
              <div className="columns">
                <div className="column is-4"></div>
                <div className="column">
                  {/* <h1 style={{textAlign: "center", marginBottom: "1rem", padding: "0"}}>{post.heading}</h1> */}
                  <MarkdownViewer markdown={post.heading} />
                </div>
                <div className="column is-4"></div>
              </div>
            </section>
          </div>

          <section
            className="body-sections section"
            style={{ paddingTop: "0", paddingBottom: "80px" }}
          >
            <div className="columns">
              <div className="column is-5"></div>
              <div className="column">
                <MarkdownViewer markdown={post.body} />
              </div>
              <div className="column is-5"></div>
            </div>
          </section>
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const pageQuery = graphql`
  query whyChoosePage($loneUrl: String!) {
    allUniquePagesJson(filter: { title: { eq: $loneUrl } }) {
      nodes {
        title
        heading
        blurb
        metaTitle
        metaDescription
        body
      }
    }
  }
`

export default WhyChoosePage
